import * as _multihashes2 from "multihashes";

var _multihashes = "default" in _multihashes2 ? _multihashes2.default : _multihashes2;

var exports = {};
const mh = _multihashes;
const CIDUtil = {
  /**
   * Test if the given input is a valid CID object.
   * Returns an error message if it is not.
   * Returns undefined if it is a valid CID.
   *
   * @param {any} other
   * @returns {string|undefined}
   */
  checkCIDComponents: function (other) {
    if (other == null) {
      return "null values are not valid CIDs";
    }

    if (!(other.version === 0 || other.version === 1)) {
      return "Invalid version, must be a number equal to 1 or 0";
    }

    if (typeof other.codec !== "string") {
      return "codec must be string";
    }

    if (other.version === 0) {
      if (other.codec !== "dag-pb") {
        return "codec must be 'dag-pb' for CIDv0";
      }

      if (other.multibaseName !== "base58btc") {
        return "multibaseName must be 'base58btc' for CIDv0";
      }
    }

    if (!(other.multihash instanceof Uint8Array)) {
      return "multihash must be a Uint8Array";
    }

    try {
      mh.validate(other.multihash);
    } catch (err) {
      let errorMsg = err.message;

      if (!errorMsg) {
        // Just in case mh.validate() throws an error with empty error message
        errorMsg = "Multihash validation failed";
      }

      return errorMsg;
    }
  }
};
exports = CIDUtil;
export default exports;